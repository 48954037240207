<template lang="pug">
div
  .widget
    .widget-header
      h2.title Custom KPI Rules
      button.btn.ml-2(type="button", @click="editingEnabled = !editingEnabled", v-b-tooltip.hover.bottom, :title="editingEnabled ? 'Exit Edit Mode' : 'Edit'")
        i.la(:class="{'la-pencil': !editingEnabled,'la-times': editingEnabled}")
    .widget-body
      .edit-mode(v-if="editingEnabled")
        table.event-list.table
          thead
            tr
              th.text-center Enabled
              th.text-center Action
              th.text-center Level
              th.text-center Media Type
              th.text-center Metric1
              th.text-center Metric2
              th.text-center Exception Range
              th.text-center Ratio (%)
              th.text-center Min Condition
              th.text-center Min Condition Metric
              th.text-center Timeframe
              th &nbsp;
          tbody
            tr(v-for="(r, i) in value", v-if="!r.parent_rule_id", :key="r.id")
              td.event-list-item
                .form-group.no-label
                  b-form-checkbox(v-model="r.active")
              td.event-list-item
                select.form-control(v-model="r.action")
                  option(v-for="o in [...ruleActionOptions, ...addAdditionalActions(r)]", :value="o.v") {{ o.t }}
              td.event-list-item
                select.form-control(v-model="r.level")
                  option(v-for="o in ruleLevelOptions", :value="o.v") {{ o.t }}
              td.event-list-item
                select.form-control(v-model="r.media_type")
                  option(v-for="o in mediaTypeOptions", :value="o.v") {{ o.t }}
                //.input-group-wrapper(v-if="r.type === 'cpa'")
                  .input-group
                    select.form-control(v-model="r.event_idx")
                      option(v-for="(e, idx) in offer.events", :value="idx") {{ e.name }}

              td.event-list-item
                select.form-control(v-model="r.metric_base")
                  option(v-for="o in metricOptions", :value="o.v") {{ o.t }}
              td.event-list-item
                select.form-control(v-model="r.metric_target")
                  option(v-for="o in [{ v: null, t: '' }, ...metricOptions]", :value="o.v") {{ o.t }}
              td.event-list-item
                select.form-control(v-model="r.range")
                  option(value="gt") Greater Than
                  option(value="lt") Lower Than
              td.event-list-item
                input.form-control(type="number", placeholder="0.5", :value="getValue(r)", @input="updateValue(r, $event.target.value)")
              td.event-list-item
                input.form-control(type="number", placeholder="3000", v-model="r.min_condition")
              td.event-list-item
                select.form-control(v-model="r.min_condition_metric")
                  option(v-for="o in metricOptions", :value="o.v") {{ o.t }}
              td.event-list-item
                select.form-control(v-model="r.timeframe")
                  option(v-for="o in timeframeOptions", :value="o.v") {{ o.t }}

              td.actions
                a.btn.btn-secondary(@click="value.splice(i, 1)", href="javascript:void(0)")
                  i.la.la-trash
        p
          button.btn.btn-secondary(type="button", @click="addRule()")
            i.la.la-plus

      div(v-else)
        ul.rule-list(v-for="r in value")
          li.rule-summary(:class="{'text-muted':!r.active}", v-if="!r.parent_rule_id", :key="r.id")
            .highlight {{r.action}}
            | whenever {{ r.level === 'offer' ? 'an' : 'a' }}
            .highlight {{r.level}}'s
            | ratio of
            .highlight {{ metricHash[r.metric_target]}} / {{metricHash[r.metric_base]}}
            | within the past
            .highlight {{ r.timeframe }} hrs
            | is
            .highlight {{r.range === 'gt' ? 'Greater' : 'Lower'}} Than
            .highlight {{getValue(r)}}%
            span(v-if="r.min_condition > 0") - provided that there were more than
              .highlight {{r.min_condition}} {{metricHash[r.min_condition_metric]}}
            | for
            .highlight {{ getMediaTypeLabel(r.media_type) }}
            | media type

  .widget
    .widget-header
      h2.title General KPI Rules
    .widget-body
      p Disable general KPI rules per offer
      .ov-table-wrapper
        table.table.table-bordered.table-hover.table-finance
          thead
            tr
              th Rule
              th Action
              th Timeframe
          tbody
            tr(v-if="!generalCustomKpiRules.length")
              td(colspan="4") No matching records were found
            tr(v-for="r in generalCustomKpiRules", :class="{'disabled-rule': shouldHighlightRule(r)}", :key="r.id")
              td
                .d-flex.justify-content-between
                  div
                    h5 {{ r.name }}
                    div {{ r.rule_text }}
                    div(v-if="r.min_condition") {{ r.min_condition_text }}
                  div(v-b-tooltip.hover="generalRuleCanBeToggled(r) ? 'Toggle rule' : 'Rule cannot be toggled as it disabled globally'")
                    toggle-button(v-model="r.active", @change="toggleGeneralRule(r)",
                      :labels="{checked: 'Active', unchecked: 'Inactive'}",
                      :width="100", :height="26", :font-size="9", :disabled="!generalRuleCanBeToggled(r)")
              td {{ r.action }}
              td {{ r.timeframe }} {{ r.timeframe > 1 ? 'hours' : 'hour' }}
</template>

<style lang="scss">
.disabled-rule {
  background: #f6cdd1 !important;
}

ul.rule-list {
  li.rule-summary {
    font-size: 13px;

    .highlight {
      &::first-letter {
        text-transform: capitalize;
      }

      display: inline-block;
      margin-left: 2px;
      margin-right: 2px;
      padding-left: 1px;
      padding-right: 1px;
    }

    &:not(.text-muted) {
      .highlight {
        font-weight: 700;
        background: #ffb05075;
      }
    }

    &.text-muted {
      text-decoration: line-through;

      .highlight {
        text-decoration: line-through;
      }
    }
  }
}
</style>

<script>
import Vue from 'vue';

/** @typedef {import('typings/models').Rule} Rule */


export default {
  name: 'kpi-rules',
  props: {
    value: Array,
    events: {
      type: Array,
      default() {
        return Array(10).fill({ name: '' });
      },
    },
    generalKpiRules: {
      type: Array,
      default() {
        return [];
      },
    },
    generalCustomKpiRules: {
      type: Array,
      default() {
        return [];
      },
    },
    offer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      ready: false,
      editingEnabled: false,
      ruleActionOptions: Vue.ovData.rule.actionOptions,
      ruleLevelOptions: Vue.ovData.rule.levelOptions,
      ruleTypeOptions: Vue.ovData.rule.typeOptions,
      mediaTypeOptions: Vue.ovData.rule.mediaTypeOptions,
      timeframeOptions: [1, 2, 3, 4, 5, 6, 12, 24, 48, 72, 168].map((v) => ({
        v: v,
        t: v === 1 ? '1 Hour' : v + ' Hours',
      })),
    };
  },

  mounted() {
    this.ready = true;
  },

  computed: {
    metricOptions() {
      return [
        { v: 'passed_impressions', t: 'Passed Impressions' },
        { v: 'passed_clicks', t: 'Passed Clicks' },
        { v: 'installs', t: 'Installs' },
        { v: 'reject_events', t: 'Rejected Events' },
        { v: 'conversions', t: 'Conversions' },
        { v: 'rejected_and_install', t: 'Rj & Ins' },
        { v: 'blocked_percent', t: 'Blocked Percentage' },
        { v: 'cr', t: 'CR' },
        ...this.events.map((e, idx) => ({ v: 'e' + (idx + 1), t: `e${idx + 1}: ${e.name}` })),
      ];
    },
    metricHash() {
      return this.metricOptions.reduce((acc, o) => {
        acc[o.v] = o.t;
        return acc;
      }, {});
    },
  },

  methods: {
    emitChange() {
      this.$emit('update-kpi-rules', this.value);
    },

    getMediaTypeLabel(v) {
      return this.mediaTypeOptions.find((o) => o.v === v).t;
    },

    /** @param {Rule} rule @param {string} value */
    updateValue(rule, value) {
      rule.value = +value / 100;
    },

    /** @param {Rule} rule */
    getValue(rule) {
      return rule.value * 100;
    },

    /** @param {Rule} rule */
    addAdditionalActions(rule) {
      if (rule.level !== 'sub1' && rule.level !== 'sub2') {
        return [];
      }

      return [
        { v: 'pause', t: 'Pause' },
      ];
    },

    /** @param {Rule} rule */
    shouldHighlightRule(rule) {
      return this.generalRuleCanBeToggled(rule) && !rule.active;
    },

    addRule() {
      this.value.push({
        active: true,
        action: this.ruleActionOptions[0].v,
        type: 'cr',
        level: this.ruleLevelOptions[0].v,
        range: 'lt',
        metric_base: 'passed_clicks',
        metric_target: 'installs',
        value: 0,
        min_condition: 0,
        min_condition_metric: 'passed_clicks',
        timeframe: 24 * 60,
      });
    },

    selectRuleType(r) {
      console.log(r.type);
      switch (r.type) {
        case 'ctr':
          r.metric_base = 'passed_impressions';
          r.metric_target = 'passed_clicks';
          r.min_condition_metric = 'passed_impressions';
          break;
        case 'cr':
          r.metric_base = 'passed_clicks';
          r.metric_target = 'passed_installs';
          r.min_condition_metric = 'passed_clicks';
          break;
      }
    },

    /** @param {Rule} rule */
    generalRuleCanBeToggled(rule) {
      const generalRule = this.generalKpiRules.find((r) => r.id === rule.id);
      return generalRule.active;
    },

    /** @param {Rule} rule */
    toggleGeneralRule(rule) {
      // If rule disable, we should add it to the offer
      const offerRule = this.value.find((or) => or.parent_rule_id === rule.id);
      if (!offerRule) {
        this.$emit('input', [...this.value, {
          ...rule,
          parent_rule_id: rule.id,
          active: false,
          offer_id: this.offer.id,
          id: undefined,
        }]);
        return;
      }

      // Remove rule from offer rules array instead of setting it to active
      const withoutRemovedRule = this.value.filter((or) => or.parent_rule_id !== rule.id);
      this.$emit('input', withoutRemovedRule);
    },
  },
};
</script>
