<template lang="pug">
	div.default-modal
		loading(:active.sync="busy", :is-full-page="false")
		.modal-header
			.modal-title Upload Creative
			a.close(href="javascript:void(0);", @click="$emit('close')")
				i.la.la-times
		.modal-body
			.modal-body-inner
				.form-group(v-if="!files.length")
					doc-list(:files="entities", :single="true", :cb="onFileReady")

				form(@submit.prevent="submit", v-if="files.length")
					div(v-for="(creative, index) in files", v-if="creative.url")

						.form-group
							label Name
							.input-group
								input.form-control(type="text", placeholder="", v-model="creative.name")
								div.input-group-append
									b-button(@click="clearFile(index)")
										i.la.la-times

						.form-group
							label Type
							input.form-control(type="text", placeholder="", v-model="creative.type", readonly="")

						.form-group(v-if="creative.type === 'image'")
							label Dimensions
							input.form-control(type="text", placeholder="", v-model="creative.w + 'x' + creative.h", readonly="")

						.form-group(v-if="creative.type === 'video'")
							label Duration
							input.form-control(type="text", placeholder="", v-model="creative.duration + ' sec'", readonly="")

						hr

					.buttons.text-right
						button.btn.btn-primary(type="submit") Save
							i.la.la-fw.la-save
</template>
<script>
import Vue from 'vue';
export default {
  name: 'CreativeModal',
  props: ['entities'],
  methods: {
    async submit() {
      if (this.busy) {
        return;
      }
      this.files = this.files.filter((f) => {
        return !!f.url;
      });
      let data = Vue.util.extend({}, this.files);
      try {
        let resp = await this.$ovReq.post('creative/save', data);
        this.$ovNotify.success('Creative has been saved');

        resp.entities.forEach((entity) => {
          this.entities.push(entity);
        });

        this.$emit('close');
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
    async onFileReady(file) {
      let meta = await this.getMeta(file.full_path);
      if (!meta) {
        this.$ovNotify.error('Unsupported file!');
        return;
      }
      let creative = this.$ovData.creative.newInstance();
      creative.type = meta.type;
      creative.w = meta.width;
      creative.h = meta.height;
      creative.duration = meta.duration;
      creative.url = file.full_path;
      creative.name = file.name;
      this.files.push(creative);
    },
    clearFile(index) {
      this.files = this.files.filter((f, i) => {
        return i !== index;
      });
    },
    getMeta(url) {
      let meta = {
        type: null,
        width: null,
        height: null,
        duration: null,
      };
      meta.type = this.getFileType(url);

      switch (meta.type) {
        case 'image':
          return new Promise((resolve) => {
            let img = new Image();

            img.onload = function () {
              meta.height = img.height;
              meta.width = img.width;
              resolve(meta);
            };
            img.src = url;
          });

        case 'video':
          return new Promise((resolve) => {
            let vid = document.createElement('video');
            vid.ondurationchange = function () {
              meta.duration = Math.round(this.duration);
              resolve(meta);
            };
            vid.src = url;
          });
        default:
          return null;
      }
    },
    getFileType(filename) {
      let ext =
        typeof filename != 'undefined'
          ? filename.substring(filename.lastIndexOf('.') + 1, filename.length).toLowerCase()
          : false;
      switch (ext) {
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'bmp':
          return 'image';

        case 'wmv':
        case 'avi':
        case 'mkv':
        case 'mov':
        case 'mp4':
          return 'video';

        default:
          return '';
      }
    },
  },
  data() {
    return {
      busy: false,
      files: [],
      // creative: this.$ovData.creative.newInstance(),
    };
  },
};
</script>
